<template>
  <div class="box">
      <div class="tabs">
          <div v-for="item in tabs" :key="item.id" class="tab-item" @click="changeTab(item.id)">
              <div class="tab-name">{{ item.name }}</div>
              <img v-if="activTab === item.id" src="../../assets/imgs/left-btn-1.png" alt="" class="tab-bg">
              <img v-else src="../../assets/imgs/left-btn-2.png" alt="" class="tab-bg">
          </div>
      </div>

      <div class="chart-box">
          <v-chart v-if="activTab===0" class="chart" :option="option1" />
          <v-chart v-else class="chart" :option="option2" />
      </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart,  PieChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components"
use([
  CanvasRenderer,
  LineChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
])

import { getCouponData } from "@/api/api.js";

export default {
  data(){
      return {
          activTab: 0,
          timer: null,
          tabs:[
              { name: '投诉建议', id: 0 },
              { name: '处理率', id: 1 },
          ],
          option1: {
              tooltip: {
                  trigger: 'axis',
                  backgroundColor: '#10408F',
                  borderColor: '#3BB9F4',
                  textStyle: {
                      color: '#ffffff',
                      fontSize: 12,
                  },
              },
              legend: {
                top: 34,
                itemGap: 30,
                data: ['建议', '投诉'],
                textStyle: {
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'bold',
                },
              },
              grid: {
                  top: 80,
                  left: '5%',
                  right: '5%',
                  bottom: 52,
                  containLabel: true,
              },
              xAxis: {
                  show: true,
                  type: 'category',
                  data: [],
                  axisLabel: {
                      color: '#88D6FF',
                      fontSize: 12,
                      fontWeight: 'bold',
                  },
              },
              

              yAxis: [
                  {
                  show: true,
                  type: 'value',
                  min: 1,
                  // interval: 1,
                  minInterval: 1,
                  axisLine: {
                      show: false,
                  },
                  axisTick: {
                      show: false,
                  },
                  axisLabel: {
                      color: '#E2ECF1',
                      fontSize: 12,
                      fontWeight: 'bold',
                  },
                  splitLine: {
                      lineStyle: {
                      color: '#ffffff',
                      whidth: 0.5,
                      opacity: 0.2,
                      type: 'dashed',
                      },
                  },
                  },
              ],
              colors:['#64A5FF', '#5AD8A6'],
              series: [
                  {
                      data: [],
                      name: '建议',
                      type: "line",
                  },
                  {
                      data: [],
                      type: "line",
                      name:'投诉',
                  },
              ],
          },

          option2: {
              tooltip: {
                  trigger: 'axis',
                  backgroundColor: '#10408F',
                  borderColor: '#3BB9F4',
                  textStyle: {
                      color: '#ffffff',
                      fontSize: 12,
                  },
              },
              legend: {
                top: 34,
                itemGap: 30,
                data: ["投诉处理率"],
                textStyle: {
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'bold',
                },
              },
              grid: {
                  top: 80,
                  left: '5%',
                  right: '5%',
                  bottom: 52,
                  containLabel: true,
              },
              xAxis: {
                  show: true,
                  type: 'category',
                  data: [],
                  axisLabel: {
                      color: '#88D6FF',
                      fontSize: 12,
                      fontWeight: 'bold',
                  },
              },
              yAxis: [
                  {
                  show: true,
                  type: 'value',
                  axisLine: {
                      show: false,
                  },
                  axisTick: {
                      show: false,
                  },
                  axisLabel: {
                      color: '#E2ECF1',
                      fontSize: 12,
                      fontWeight: 'bold',
                  },
                  splitLine: {
                      lineStyle: {
                      color: '#ffffff',
                      whidth: 0.5,
                      opacity: 0.2,
                      type: 'dashed',
                      },
                  },
                  },
              ],
              colors:['#64A5FF', '#5AD8A6'],
              series: [
                  // {
                  //     data: [],
                  //     name: '建议处理率',
                  //     type: "line",
                  // },
                  {
                      data: [],
                      type: "line",
                      name:'投诉处理率',
                  },
              ],
          },
      }
  },
  mounted() {
      this.getData();
      this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
      clearInterval(this.timer);
  },
  methods:{
      changeTab(id){
          this.activTab = id;
          this.getData();
      },
      getData() {
        const params = {
          "reqCodes": [31291003],
          "params":  { "counts": 7 }
        }
        getCouponData(params).then((res) => {
          const items = res.data.data[31291003].reverse()
          if (this.activTab===0) {
            this.option1.xAxis.data = items.map((o) => {
              return o["日期"].split("-").slice(1,3).join('-')
            })
            this.option1.series[0].data = items.map((o) => {
              return o["投诉"]
            })
            this.option1.series[1].data = items.map((o) => {
              return o["建议"]
            })
          } else {
            this.option2.xAxis.data = items.map((o) => {
              return o["日期"].split("-").slice(1,3).join('-')
            })
            this.option2.series[0].data = items.map((o) => {
              return o["投诉处理率"]
            })
          }
        })
    },
  }
}
</script>

<style scoped>
.box{
  position: absolute;
  right: 40px;
  top: 658px;
  width: 346px;
  height: 374px;

  background-image: url("../../assets/imgs/block-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 15;
}
.tabs{
  position: absolute;
  top: 0;
  left: -34px;
}
.tab-item{
  position: relative;
  width: 34px;
  height: 94px;
  margin-top: 10px;
  cursor: pointer;
}
.tab-bg{
  width: 100%;
  height: 100%;
}
.tab-name{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 64px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}
.chart-box{
  width: 100%;
  height: 100%;
}

</style>
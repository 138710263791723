<template>
  <div class="weather-info">
    <span class="txt-1">PM2.5值：</span>
    <span class="txt-2">{{ weather_info.aqi }}{{ weather_info.qlty }}</span>
    <span class="txt-1 ml20">温度：</span>
    <span class="txt-2">{{ weather_info.temp }}℃</span>
    <span class="txt-2 ml20">{{ weather_info.cond_txt }}</span>
  </div>
</template>

<script>
import { getWeather } from "@/api/api.js";
export default{
  data(){
    return{
      weather_info:{},
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    getData(){
      getWeather().then((res) => {
        this.weather_info = res.data.data;
      });
    }
  }
}
</script>

<style scoped>
.weather-info{
  position: fixed;
  top: 1038px;
  left: 40px;
  z-index: 15;
}
.txt-1{
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3EDFF;
  line-height: 18px;
}
.txt-2{
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3EDFF;
  line-height: 28px;
}
.ml20{
  margin-left: 20px;
}
</style>
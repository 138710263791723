<template>
  <div class="date-info">
    <span>{{ dateStr }}</span>
    <span class="ml20">{{ weekStr }}</span>
    <span class="ml20 time-str">{{ timeStr }}</span>
  </div>
</template>

<script>
const moment = require("moment"); // 引入

export default{
  data(){
    return {
      dateStr: '',
      weekStr: '',
      timeStr: '',
      timer: null,
    }
  },
  mounted(){
    this.startTimer();
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods:{
    startTimer(){  
      this.timer = setInterval(() => {
        const now = Date.now();
        this.handleTime(now);
      }, 1000);
    },
    handleTime(time){
      const date = moment(time);
      const weeks = ['日', '一', '二', '三', '四', '五', '六'];
      const str = date.format('YYYY-MM-DD HH:mm:ss');
      const arr = str.split(' ');
      this.dateStr = arr[0];
      this.timeStr = arr[1];
      this.weekStr = `星期${weeks[date.day()]}`;
    }
  }
}
</script>

<style scoped>
.date-info{
  position: fixed;
  top: 1038px;
  right: 40px;

  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3EDFF;
  line-height: 22px;
  z-index: 15;
}
.time-str{
  display: inline-block;
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #D3EDFF;
  line-height: 28px;
}
.ml20{
  margin-left: 20px;
}
</style>
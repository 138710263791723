<template>
    <div class="page-inner">
        <LeftBox1Vue />
        <LeftBox2Vue />
        <LeftBox3Vue />
        <RightBox1Vue />
        <RightBox2Vue />
        <RightBox3Vue />
        <WeatherVue />
        <DateInfoVue />
        <!-- <FooterVue /> -->
        <TopDataVue />
    </div>
</template>

<script>
import LeftBox1Vue from '../components/Overview-v2/LeftBox1.vue';
import LeftBox2Vue from '../components/Overview-v2/LeftBox2.vue';
import LeftBox3Vue from '../components/Overview-v2/LeftBox3.vue';
import RightBox1Vue from '../components/Overview-v2/RightBox1.vue';
import RightBox2Vue from '../components/Overview-v2/RightBox2.vue';
import RightBox3Vue from '../components/Overview-v2/RightBox3.vue';
import WeatherVue from '../components/Overview-v2/Weather.vue';
import DateInfoVue from '../components/Overview-v2/DateInfo.vue';
// import FooterVue from '../components/Overview-v2/Footer.vue';
import TopDataVue from '../components/Overview-v2/TopData.vue';

export default {
    components:{
        LeftBox1Vue,
        LeftBox2Vue,
        LeftBox3Vue,
        RightBox1Vue,
        RightBox2Vue,
        RightBox3Vue,
        WeatherVue,
        DateInfoVue,
        // FooterVue,
        TopDataVue
    }
    
}
</script>

<style scoped>

</style>
<template>
  <div class="title" @click="handleClick">
    <div class="lottie" ref="lottie"></div>
    <div class="title-txt">{{ title }}</div>
  </div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  props:{
    title: {
      type: String,
      default: ''
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    init(){
      lottie.loadAnimation({
                container: this.$refs.lottie,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: "/static/lottie/block-title.json"
            });
    },
    handleClick(){
      this.$emit('titletap')
    }
  }
}
</script>

<style scoped>
.title{
  position: relative;
  width: 380px;
  height: 39px;
  cursor: pointer;
}
.lottie{
  width: 100%;
  height: 100%;
}
.title-txt{
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  font-size: 22px;
  font-family: iFontszhounianti;
  color: #FFFFFF;
  line-height: 39px;
}
</style>
<template>
  <!-- 人流预警 -->
  <section class="store-info">
    <BlockTitle title="投诉建议" />
    <!-- <div class="block-title">投诉建议</div> -->
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th class="col1">类型</th>
            <th class="col2">内容</th>
            <th class="col3">时间</th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(point, i) in points" :key="i" :class=" i%2===0?'bg':'bg2' ">
            <td>
              <div class="col1">
                {{ point["类型"] }}
              </div>
            </td>
            <td>
              <div class="col2">{{ point["内容"] }}</div>
            </td>
            <td>
              <div class="col3">
                {{ point["时间"].slice(0, 10) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="points.length === 0" class="empty-data">暂无数据</div>
    </div>
  </section>
</template>
<script>
import { getCouponData } from "@/api/api.js";
import BlockTitle from "./BlockTitle.vue";

export default {
  components:{
    BlockTitle
  },
  data() {
    return {
      animateUp: false,
      timer: null,
      points: [],
    };
  },
  methods: {
    scrollAnimate() {
    },
    getData() {
      const params = {
        "reqCodes": [31291004],
        "params": {}
      }
      getCouponData(params).then((res) => {
        this.points = res.data.data[31291004]
      })
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
};
</script>
<style scoped>
.store-info{
  position: fixed;
  top: 414px;
  right: 40px;
  z-index: 15;
}
.block-title{
  box-sizing: border-box;
  padding-left: 25px;
  width: 380px;
  height: 39px;
  background-image: url("../../assets/imgs/block-title-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  font-size: 22px;
  font-family: iFontszhounianti;
  color: #FFFFFF;
  line-height: 39px;
}
.store-info table {
  width:100%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #88D6FF;
  line-height: 20px;
}

.store-info table thead tr{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.store-info table thead tr th{
  padding: 10px 0 5px 0;
}
.store-info table .bg {
  height: 30px;
  line-height: 30px;
  background: linear-gradient(90deg, rgba(100,165,255,0.2) 0%, rgba(100,165,255,0) 100%);
  border-radius: 1px;
}

.store-info table .bg2 {
  height: 30px;
  line-height: 30px;
}

.store-info table td{
  text-align:center;
  width: 24px;
  font-size: 14px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #88D6FF;
}


.store-list {
  height: 190px;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  color: '#fff';
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
.col2{
  width: 192px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.col3{
  width: 80px;
  white-space: nowrap;
  text-align: center;
}
.empty-data{
  width: 100%;
  margin-top: 30px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
</style>

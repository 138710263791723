<template>
  <div class="box">
    <BlockTitle title="商圈事故统计" />
    <!-- <div class="block-title">商圈事故统计</div> -->
    <div class="box-inner">
      <ul class="bars">
        <li class="bar-item" v-for="item in events" :key="item.name">
          <p class="bar-title">{{ item.name }}</p>
          <div class="bar">
            <div class="bar-inner" :style="{ width: item.rate }"></div>
            <span class="bar-num">{{ item.num }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCouponData } from "@/api/api.js";
import BlockTitle from "./BlockTitle.vue";

export default{
  components:{
    BlockTitle
  },
  data(){
    return {
      timer: null,
      events: [],
    }
  },
  methods: {
    getData() {
      const params = {
        "reqCodes": [31291000],
        "params": { }
      }
      getCouponData(params).then((res) => {
        const items = res.data.data[31291000]
        let total = 0
        items.forEach(item=>{
          total += Number(item["数量"])
        })
        this.events = items.map((item) => {
          let _item = {}
          _item.name = item["类型"]
          _item.num = item["数量"]
          _item.rate = (item["数量"]/total*100) +'%'
          return _item
        })
      })
    }
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
.box{
  position: fixed;
  top: 108px;
  right: 40px;
  z-index: 15;
}
.block-title{
  box-sizing: border-box;
  padding-left: 25px;
  width: 380px;
  height: 39px;
  background-image: url("../../assets/imgs/block-title-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  font-size: 22px;
  font-family: iFontszhounianti;
  color: #FFFFFF;
  line-height: 39px;
}
.box-inner{
  overflow: hidden;
  position: relative;
  width: 380px;
  height: 237px;
  margin-top: 10px;

  background-image: url("../../assets/imgs/block-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.bars{
  margin-left: 22px;
}
.bar-item{
  margin-top: 22px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.bar{
  width: 300px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  background: rgba(255,255,255,0.15);
  border-radius: 0 10px 10px 0;
}
.bar-inner{
  height: 20px;

  background: linear-gradient(90deg, #FF762A 0%, #FAE262 100%);
  border-radius: 0 10px 10px 0;
}
.bar-num{
  margin-left: 4px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
}
.bar-title{
  margin-right: 9px;
  width: 30px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #88D6FF;
  line-height: 20px;
}
</style>
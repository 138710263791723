<template>
  <div class="box">
    <BlockTitle title="各景点人流量" @titletap="goJingDianRenliu"/>
    <!-- <div class="block-title" @click="goJingDianRenliu">各景点人流量</div> -->
    <div class="box-inner">
      <div class="top-title">承载量</div>
      <div class="left-title">当前人数</div>
      <ul class="bars">
        <li class="bar-item" v-for="item in points" :key="item.name">
          <div class="total-num">{{ item.limitNum }}</div>
          <div class="bar">
            <span class="bar-num">{{ item.inNum }}</span>
            <div class="bar-inner" :style="{ height: item.rate }"></div>
          </div>
          <p class="bar-title">{{ item.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getScenePassengers } from "@/api/api.js";
import BlockTitle from "./BlockTitle.vue";
export default{
  components:{
    BlockTitle
  },
  data(){
    return {
      timer: null,
      points:[],
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
  methods:{
    goJingDianRenliu(){
      this.$router.push({
        path: '/jingdianguanli'
      })
    },
    getData() {
      const dataMap = {
        "三味书屋-免费": 241,
        "鲁迅故居-免费": 1384,
        "鲁迅纪念馆": 1139,
        "鲁迅祖居-免费": 205,
      }
      getScenePassengers().then((res) => {
        this.points = res.data.data.filter(point => {
          point.inNum = point.inNum || dataMap[point.name] || 0;
          point.rate = (point.inNum/point.limitNum*100)+'%'
          if(["三味书屋-免费", "鲁迅故居-免费", "鲁迅纪念馆", "鲁迅祖居-免费"].includes(point.name)) {
            return true
          } else {
            return false
          }
        });
      });
    },
  }
}
</script>

<style scoped>
.box{
  position: fixed;
  top: 759px;
  left: 40px;
  z-index: 15;
}
.block-title{
  box-sizing: border-box;
  padding-left: 25px;
  width: 380px;
  height: 39px;
  background-image: url("../../assets/imgs/block-title-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  font-size: 22px;
  font-family: iFontszhounianti;
  color: #FFFFFF;
  line-height: 39px;
  cursor: pointer;
}
.box-inner{
  overflow: hidden;
  position: relative;
  width: 380px;
  height: 224px;
  margin-top: 10px;

  background-image: url("../../assets/imgs/block-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.top-title{
  position: absolute;
  top: 14px;
  left: 15px;
  width: 36px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
}
.left-title{
  position: absolute;
  top: 68px;
  left: 25px;
  width: 16px;
  height: 68px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
}
.bars{
  margin-top: 14px;
  margin-left: 41px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}
.bar-item{
  width: 84px;
  min-width: 84px;
  max-width: 84px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.total-num{
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
}
.bar{
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 9px;
  width: 20px;
  height: 136px;
  background: #D3E2FF;
  box-shadow: inset 1px 1px 3px 0px rgba(0,0,0,0.5), inset -1px 1px 3px 0px #FFFFFF;
  border-radius: 10px 10px 0 0;
}
.bar-inner{
  width: 20px;
background: linear-gradient(180deg, #1E1DC0 0%, #5BAFF9 100%);
border-radius: 10px 10px 0 0;
}
.bar-num{
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F20C1;
  line-height: 17px;
}
.bar-title{
  margin-top: 8px;
  text-align: center;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #92DBFF;
  line-height: 20px;
}
</style>
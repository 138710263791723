<template>
    <div class="box">
        <div class="tabs">
            <div v-for="item in tabs" :key="item.id" class="tab-item" @click="changeTab(item.id)">
                <div class="tab-name">{{ item.name }}</div>
                <img v-if="activTab === item.id" src="../../assets/imgs/right-bnt-1.png" alt="" class="tab-bg">
                <img v-else src="../../assets/imgs/right-btn-2.png" alt="" class="tab-bg">
            </div>
        </div>

        <div class="chart-box">
            <v-chart v-if="activTab===0" class="chart" :option="option1" />
            <div v-else class="chart-pie">
                <v-chart class="chart" :option="option2" />
                <div class="total-shop">
                    <span class="total-shop-num">{{ totalShop }}</span>
                    <span>家</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart,  PieChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  LineChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import { getHeatingPowerDaily, getIndestryInfo } from "@/api/api.js";

export default {
    data(){
        return {
            activTab: 0,
            timer: 0,
            tabs:[
                { name: '每日人流', id: 0 },
                { name: '商家数量', id: 1 },
            ],
            option1: {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#10408F',
                    borderColor: '#3BB9F4',
                    textStyle: {
                        color: '#ffffff',
                        fontSize: 12,
                    },
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: 40,
                    left: '5%',
                    right: '5%',
                    bottom: 32,
                    containLabel: true,
                },
                xAxis: {
                    show: true,
                    type: 'category',
                    data: [],
                    axisLabel: {
                        color: '#88D6FF',
                        fontSize: 12,
                        fontWeight: 'bold',
                    },
                },
                

                yAxis: [
                    {
                    show: true,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#E2ECF1',
                        fontSize: 12,
                        fontWeight: 'bold',
                    },
                    splitLine: {
                        lineStyle: {
                        color: '#ffffff',
                        whidth: 0.5,
                        opacity: 0.2,
                        type: 'dashed',
                        },
                    },
                    },
                ],
                series: [
                    {
                        data: [1, 5, 4, 2, 10],
                        type: "line",
                        lineStyle:{
                            color: '#64A5FF',
                            width: 2,
                        },
                        itemStyle: {
                            opacity: 0
                        },
                    },
                ],
            },
            totalShop: 0,
            option2:{
                legend: {
                    type: "plain",
                    orient: "vertical",
                    right: "5%",
                    top: "25%",
                    textStyle: {
                        color: "#ffffff",
                        fontSize: "12",
                    },
                    itemHeight: 9,
                    itemWidth: 9,
                    itemGap: 5,
                },
                tooltip: {
                    trigger: "item",
                    formatter: '{b}：{c}%'
                },
                series: [{
                    name: "商家数量",
                    type: "pie",
                    radius: [45, 90],
                    center: ["30%", "50%"],
                    data: [],
                    
                    itemStyle: {
                        normal: {
                            label: {
                                show: false,
                            }
                        }
                    },
                    left: "5%",
                }, 
                ],
            }
        }
    },
    mounted() {
        this.getData();
        this.timer = setInterval(this.getData, 10000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods:{
        changeTab(id){
            this.activTab = id;
        },
        getData() {
            getHeatingPowerDaily().then((res) => {
                res.data.data.map((item) => {
                if (item.head_count.data_total_num < 20000) {
                    item.head_count.data_total_num +=
                    20000 + Math.floor(Math.random() * 500);
                }
                return item;
                });
                let result = res.data.data.map((item) => {
                return {
                    name: item.data_time.split("-").slice(1, 3).join("-"),
                    num: item.head_count.data_total_num,
                };
                });

                // const sortedResult = [...result].sort(function (a, b) {
                // return b.num - a.num;
                // });

                this.option1.series[0].data = result.map((o) => {
                    // let i = sortedResult.findIndex((_d) => {
                    //     return _d.name == o.name;
                    // });
                    return {
                        value: o.num,

                    };
                });
                this.option1.xAxis.data = result.map((o) => {
                    return o.name;
                });
            });

            getIndestryInfo().then((res) => {
                // this.$emit('updateStoreCount', res.data.data.total)
                this.totalShop = res.data.data.total;
                this.option2.series[0].data = res.data.data.list.map((o) => {
                    return {name: o.type_name, value: o.percent}
                }).slice(0, 8)
            });
        },
    }
}
</script>

<style scoped>
.box{
    position: fixed;
    left: 40px;
    top: 442px;
    width: 346px;
    height: 297px;

    background-image: url("../../assets/imgs/block-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 15;
}
.tabs{
    position: absolute;
    top: 0;
    right: -34px;
}
.tab-item{
    position: relative;
    width: 34px;
    height: 94px;
    margin-top: 10px;
    cursor: pointer;
}
.tab-bg{
    width: 100%;
    height: 100%;
}
.tab-name{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 64px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 16px;
}
.chart-box{
    width: 100%;
    height: 100%;
}
.chart-pie{
    width: 100%;
    height: 100%;
}
.total-shop{
    position: absolute;
    top: 130px;
    left: 95px;
}
.total-shop span:last-child{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}
.total-shop-num{
    margin-right: 5px;
    font-size: 28px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #88D6FF;
    line-height: 32px;
}
</style>
<template>
  <div class="top-data">
    <div class="tab-item" @click="goRenliuPage">
      <div class="num-row">
        <div class="num-cell">{{ traffice_info.head_count.data_current_num }}</div>
        <div class="txt-1">人</div>
      </div>
      <div class="tab-name">当前总人数</div>
    </div>

    <div class="tab-item" @click="goShangJiaPage">
      <div class="num-row">
        <div class="num-cell">{{ monthSale || 64.21 }}</div>
        <div class="txt-1"></div>
      </div>
      <div class="tab-name">月零售额</div>
    </div>

    <div class="tab-item" @click="goShangJiaPage">
      <div class="num-row">
        <div class="txt-2">合计</div>
        <div class="num-cell">{{ totalMember || 1021 }}</div>
        <div class="txt-1">人</div>
      </div>
      <div class="tab-name">会员情况</div>
    </div>

    <div class="tab-item" @click="goShangJiaPage">
      <div class="num-row">
        <div class="txt-2">领取</div>
        <div class="num-cell red">{{ receiveCoupon || 763  }}</div>
        <div class="txt-1">个</div>
      </div>
      <div class="num-row mt5">
        <div class="txt-2">使用</div>
        <div class="num-cell green">{{ useCoupon || 465 }}</div>
        <div class="txt-1">个</div>
      </div>
      <div class="tab-name">优惠券情况</div>
    </div>

  </div>
</template>

<script>
import {
  getHeatingPower,
  getMPMemberInfo,
  getCouponData
} from "@/api/api.js";
const moment = require("moment"); // 引入

export default{
  data(){
    return{
      timer: null,
      traffice_info:{
        head_count:{
          data_current_num: 0
        }
      },
      monthSale: 0,
      totalMember: 0,
      useCoupon: 0,
      receiveCoupon: 0
    }
  },
  mounted(){
    this.getRealTimeData();
    this.getData();
    this.timer = setInterval(this.getRealTimeData, 6000);
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods:{
    goRenliuPage(){
      this.$router.push({
        path: '/renliufenxi'
      })
    },
    goShangJiaPage(){
      this.$router.push({
        path: '/shangpuguanli'
      })
    },
    getRealTimeData() {
      getHeatingPower().then((res) => {
        this.traffice_info = res.data.data;
      });

      const month = moment().format("YYYY-MM")
      const params = {
        "reqCodes": [21251000],
        "params": { start_month: month, end_month: month }
      }
      return getCouponData(params).then((res) => {
        const data = res.data.data["21251000"]
        if(data){
          this.useCoupon = data["券核销数量"]
          this.receiveCoupon = data["券领取数量"]
        }
      })
    },
    getData(){
      getMPMemberInfo().then(res=>{
        const datas = (res.data?.data?.usercumulate_list||[]).map((list) => {
          return list.cumulate_user;
        })
        let totalMember = 0;
        datas.forEach(num=>{
          totalMember+=Number(num);
        })
        this.totalMember = totalMember;
      })
      const params = {
        "reqCodes": [31111000],
        "params": { }
      }
      getCouponData(params).then((res) => {
        const data = res.data.data["31111000"]
        if(data){
          const month = moment().month()
          this.monthSale = data[month]["金额"]
        }
      })
    }
  }
}
</script>

<style scoped>
.top-data{
  position: fixed;
  top: 99px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 15;
}
.tab-item{
  margin-right: 29px;
  padding: 11px 21px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  background-image: url("../../assets/imgs/top-block-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.tab-item:last-child{
  margin-right: 0;
}
.num-row{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.num-cell{
  box-sizing: border-box;
  min-width: 112px;
  padding: 4px 10px;
  text-align: center;
  font-size: 28px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 32px;
  background-image: url("../../assets/imgs/top-block-num-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.txt-1, .txt-2{
  margin-left: 10px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3EDFF;
  line-height: 20px;
}
.txt-2{
  margin-left: 0;
  margin-right: 10px;
}
.tab-name{
  margin-top: 10px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #88D6FF;
  line-height: 22px;
}

.red{
  color: #FF0000;
}
.green{
  color: #00FFC4;
}
.mt5{
  margin-top: 5px;
}
</style>
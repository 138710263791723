<template>
  <!-- 人流预警 -->
  <section class="store-info">
    <BlockTitle title="人流预警" @titletap="goRenliuPage" />
    <!-- <div class="block-title" @click="goRenliuPage">人流预警</div> -->
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th class="col1">点位</th>
            <th class="col2">总进人流</th>
            <th class="col3">总出人流</th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(point, i) in points" :key="i" :class=" i%2===0?'bg':'bg2' ">
            <td>
              <div class="col1">
                {{ point.group_name }}
              </div>
            </td>
            <td>
              <div class="col2">{{ point.in_flow }}</div>
            </td>
            <td>
              <div class="col3">
                {{ point.out_flow }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getPointPassengers } from "@/api/api.js";
import BlockTitle from "./BlockTitle.vue";

export default {
  components:{
    BlockTitle
  },
  data() {
    return {
      animateUp: false,
      timer: null,
      points: [],
    };
  },
  methods: {
    goRenliuPage(){
      this.$router.push({
        path: '/renliufenxi'
      })
    },
    scrollAnimate() {
    },
    getData() {
      getPointPassengers().then((res) => {
        this.points = res.data.data
        // this.points = res.data.data.filter(point => {
        //   if(point.in_flow < 10 && point.out_flow <= 10) {
        //     return false
        //   } else {
        //     return true
        //   }
        // });
      });
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
};
</script>
<style scoped>
.store-info{
  position: fixed;
  top: 108px;
  left: 40px;
  z-index: 15;
}
.block-title{
  box-sizing: border-box;
  padding-left: 25px;
  width: 380px;
  height: 39px;
  background-image: url("../../assets/imgs/block-title-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  font-size: 22px;
  font-family: iFontszhounianti;
  color: #FFFFFF;
  line-height: 39px;
  cursor: pointer;
}
.store-info table {
  width:100%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #88D6FF;
  line-height: 20px;
}

.store-info table thead tr{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.store-info table thead tr th{
  padding: 10px 0 5px 0;
}
.store-info table .bg {
  height: 30px;
  line-height: 30px;
  background: linear-gradient(90deg, rgba(100,165,255,0.2) 0%, rgba(100,165,255,0) 100%);
  border-radius: 1px;
}

.store-info table .bg2 {
  height: 30px;
  line-height: 28px;
}

.store-info table td{
  text-align:center;
  width: 24px;
  font-size: 16px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #88D6FF;
}


.store-list {
  height: 275px;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  color: '#fff';
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
</style>
